@import "@/assets/scss/main.scss";

.favorit-item {
    border-radius: 6px;
}

.starredIcon {
    position: absolute;
    top: 0px;
    right: 0px;

    .iconStarred {
        padding: 10px;
        border-radius: 6px;
    }
}
