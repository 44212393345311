@import "@/assets/scss/main.scss";

.wrapper {
    margin-bottom: 1rem;
}

.detail-card {
    height: auto;
    border: none;
    background-color: #F3F6F8;
    padding-left: 16px;
    padding-right: 16px;
}

.list-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 1rem;

    .item-group {
        background-color: #F3F6F8;
        padding: 0;

        .item {
            display: grid;
            place-content: center;
            hyphens: auto;
            background-color: #FFFFFF;
            padding: 5px 12px 5px 12px;
            gap: 8px;
            height: 100%;
            border-radius: 3px;
            text-align: center;
            justify-content: center;

            &:hover {
                background-color: lightblue;
            }
        }
    }
}


.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    background: #F3F6F8;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon {
        transform: rotate(180deg);
    }
}

.legal-item {
    display: grid;
    grid-template-columns: 20px 1fr;
    hyphens: auto;
}
