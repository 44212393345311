@import "@/assets/scss/main.scss";

.wrapper {
    margin-bottom: 16px;
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    padding: 24px 32px;
    gap: 24px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.2s;
    }

    .step-id {
        color: $elsi-color-schrift-hellgrau;
        font-weight: bold;
    }

    .step-title {
        font-weight: bold;
        flex: 1;
    }

    &[aria-expanded="true"] {
        border-radius: 6px 6px 0 0;

        .b-icon {
            transform: rotate(180deg);
        }
    }

}

.card-questions {
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 6px 6px;
    border: none;
    padding: 0 32px 0 32px;
}

.questions {
    border-top: 2px solid #E5ECF2;
    padding: 32px 0 16px 0;
}
