@import "@/assets/scss/main.scss";

@media only screen and (max-width: 1920px) {
    .overviewItem {
        height: 35em;
        max-height: auto;
    }
}

@media only screen and (min-width: 1921px) {
    .overviewItem {
        height: 40em;
        max-height: auto;
    }
}

#options-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    justify-content: center;
}

.myfooter {
    border: none;
    display: flex;
    justify-content: center;
    justify-items: center;
    background: white;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
}

#footer {
    align-self: center;
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 0 0;
    width: 100%;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon {
        transform: rotate(180deg);
    }
}

.collapsed {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.b-card-overview {
    width: 100%;
    height: 100%;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.b-card-body-overview {
    margin: -14px 20px 20px 20px;
    padding: 20px 0 20px 0;
    border-top: 2px solid #E5ECF2;
}
