@import "@/assets/scss/main.scss";

section#content {
    display: flex;
    flex-direction: column;
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: auto 25%;
    height: 200px;
    margin: 0px;
}

.favorit-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;

    .favorit-item {
        background-color: #FFFFFF;
        padding: 14px 20px;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
}

section#tool-sections {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .tool-sections {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1.5rem;
    }
}

section#overview-categorys {
    display: flex;
    width: 100%;
    flex-direction: column;

    .overview-categorys {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 75% 10% 10%;
        grid-column-gap: 1.5rem;
        height: 5rem;
        align-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.steps-overview {
    display: flex;
}

.steps {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px 0 16px 0;
    width: 100%;
    border: none;
}

.step div {
    padding: 10px;
    text-align: left;
    color: $elsi-color-schrift-normal;
}

.step .number {
    color: $elsi-color-schrift-hellgrau;
    font-weight: 700;
}
