@import "@/assets/scss/main.scss";

.editPen {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    margin: 10px 10px 0 0;
    border-radius: 6px;
    background: #ECF0F3;
}

.ownValue {
    text-align: center;
    background-color: lightgrey;

    &:focus {
        background: white;
    }
}

.tabs {
    border: none;

    /deep/ .active-tab {
        font-weight: bold;
        border-bottom: 3px solid #4BB3D4;
    }

    .nav-link .tab-title {
        color: black;
    }
}

/deep/ .tab-title-class {
    color: $elsi-color-schrift-normal;
}

.nav-link {
    background-color: red;
}

.drop-zone-delete {
    width: 29%;
    height: 4rem;
    margin-top: 20px;
    align-self: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 3px;
    border: 2px dashed $elsi-color-dunkelgrau;
    background-color: $elsi-color-mittelgrau;
    color: $elsi-color-icon-grau;
}

.drop-zone-overlay {
    aspect-ratio: 1;
    margin: 20px 0 20px 0;

    .drop-zone {
        width: 100%;
        border-radius: 50%;
        background-image: url("~@/assets/images//elsisat/Zielscheibe.svg");
        background-size: cover;
        height: 100%;
        padding: 0;
        padding-bottom: calc(100% * 3 / 4);
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
    }
}


.drop-zone-overview {
    aspect-ratio: 1;
    width: 100%;
    border-radius: 50%;
    background-image: url("~@/assets/images//elsisat/Zielscheibe.svg");
    background-size: cover;
    height: 100%;
    padding: 0;
    padding-bottom: calc(100% * 3 / 4);
    background-repeat: no-repeat;
    background-position: center center;
}

.item {
    position: absolute;
}

.get-zone {
    margin-top: 32px;
    margin-bottom: 10px;
    padding: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.addButton {
    background: lightgrey;
    color: black;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    width: 100%;
    border: none;

    &:hover {
        background: white;
    }
}



.drag-el {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: white;
        text-align: center;
    }
}

.drag-el-overview {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
}

.isActive {
    background: darken($elsi-color-blue, 8);
    color: white;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    width: fit-content;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */
}

.isInactive {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    width: fit-content;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: white;
    }
}

section#value-section {
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    // calc: 1rem margin from collapsible
    padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    h4 {
        color: $elsi-color-schrift-normal;
    }

    @media only screen and (max-width: 1920px) {
        .value-section {
            display: grid;
            grid-template-columns: 12% 41% 12% 35%;
            grid-column-gap: 1.5rem;
            width: 100%;
        }
    }

    @media only screen and (min-width: 1921px) {
        .value-section {
            display: grid;
            grid-template-columns: 12% 41% 12% 35%;
            grid-column-gap: 1.5rem;
            width: 100%;
        }
    }
}

#context-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 32px 24px;
    gap: 40px;
    overflow: hidden;
    line-height: 25px;

    /deep/ .active-tab {
        font-weight: bold;
        border-bottom: 3px solid #4BB3D4;
    }

    .tab-text {
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: scroll;
        height: 600px;
        margin-top: 32px;

        .headline {
            color: $elsi-color-schrift-normal;
            font-weight: 700;
        }

        .sub-title {
            font-weight: 700;
            color: $elsi-color-schrift-graublau;
        }
    }
}
