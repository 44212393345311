@import "@/assets/scss/main.scss";

section#content-header-cards {
    display: grid;
    grid-column-gap: 1.5rem;
    padding: 16px 0 32px 0;

    .content-header-bar-cards {
        display: grid;
        grid-template-columns: minmax(100px, 100px) auto;
        height: 5rem;
        align-content: center;
        font-weight: bold;
        grid-column-gap: 1.5rem;
    }

}

section#cards {
    display: flex;
    flex-direction: column;
    padding: 0;

    .cards {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
    }
}

.overviewCards {
    height: fit-content
}