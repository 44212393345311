@import "@/assets/scss/main.scss";

.star-hell {
    color: white;
    background-color: $star-hell;
}

.star-dunkel {
    color: white;
    background-color: $star-dunkel;
}

.starredIcon {
    position: absolute;
    top: 16px;
    right: 16px;

    .iconStarred {
        padding: 10px;
        border-radius: 6px;
    }
}

.elsi-card {
    padding: 12px, 0px, 0px, 0px;
    height: 25rem;
    overflow: hidden;
    border: none;

    &:hover {
        background-color: lightgrey;
    }
}

.elsi-card-overview {
    overflow-y: auto;
    height: auto;
}

.headline {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 20px;
    /* identical to box height, or 114% */
    color: $elsi-color-schrift-normal;
}

.headline-projectoverview {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 20px;
    /* identical to box height, or 114% */
    color: $elsi-color-schrift-normal;

}

section#card-section {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    // padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .card-section {
        display: grid;
        grid-template-columns: 55% 45%;
        grid-column-gap: 2rem;
    }

}

section#card-section-overview {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    // padding: 8px 40px calc(32px - 1rem) 40px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }


}
