@import "@/assets/scss/main.scss";

.popUp {
    display: flex;
    position: absolute;
    top: 35%;
    right: 5%;
}

label {
    display: inline !important; // 1
}

.checkbox-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/deep/ input[type="checkbox"] {
    outline: none;
    border: none;
    background-color: #F8F9FB;
}