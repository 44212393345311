@import "@/assets/scss/main.scss";

.zuordnung {
    display: flex;
    align-self: center;
    scale: 2;
}

.categoryTypes {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .marked {
        opacity: 1;
    }

    .unmarked {
        opacity: 0.5;
    }

    .type {
        display: flex;
        background-color: $elsi-color-mittelgrau;
        padding: 5px 12px 5px 12px;
        gap: 16px;
        border-radius: 6px;
        justify-content: center;
        height: 52px;
        width: 190px;
        align-items: center;
    }
}

/deep/ .list-group * {
    border: none;
}

/deep/ .traits {
    background-color: #F3F6F8 !important;
}

section#detail-section {
    background-color: white;
    border-radius: 6px;
    padding: 32px 40px 32px 32px;
    gap: 40px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

    /deep/ .overview-pic {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 50% 50%;
        padding-top: 32px;
    }

    .overview-listgroup {

        .overview-listgroup-item {
            display: flex;
            align-items: baseline;
        }

    }

    .detail-section {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 30% auto;
        grid-column-gap: 1.5rem;
    }

    .short-section {
        border-right: 4px dashed #ECF0F3;
        padding-right: 32px;
        gap: 20px;


    }

    .tabs {
        border: none;

        /deep/ .active-tab {
            font-weight: bold;
            border-bottom: 3px solid #4BB3D4;
        }
    }

    /deep/ .tab-title-class {
        color: $elsi-color-schrift-normal;
    }

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */
        color: $elsi-color-schrift-normal;
        padding-bottom: 5px;
    }

    .text-section {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2rem;
    }
}

.starredIconDetail {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5;

    .iconStarred {
        padding: 10px;
        border-radius: 6px;
    }
}
