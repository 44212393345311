@import "@/assets/scss/main.scss";

section#content-header {
    display: grid;
    grid-column-gap: 1.5rem;
    padding: 16px 0 32px 0;

    .content-header-bar {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 5% 14.5% 64% 5% 5%;
        height: 5rem;
        align-content: center;
        align-items: center;
        font-weight: bold;
        grid-column-gap: 1.5rem;
    }

    .content {
        grid-template-columns: minmax(100px, 100px) auto;
        grid-column-gap: 1.5rem;
        display: grid;
    }
}

.editPen {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    border-radius: 6px;
    background: #ECF0F3;
}

.wahrscheinlich {
    color: $elsi-color-blue;
}

.möglich {
    color: $elsi-color-lighterblue;
}

.unwahrscheinlich {
    color: $elsi-color-schrift-hellgrau;
}

section#categorys {
    display: flex;
    flex-direction: column;

    .categorys {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 1.5rem;
        word-wrap: anywhere;
        word-break: break-word;
    }

}

section#overview-categorys {
    display: flex;
    width: 100%;
    flex-direction: column;

    .overview-categorys {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 12fr 6fr 4fr 1fr;
        grid-column-gap: 1rem;
        height: 5rem;
        max-height: 10rem;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .category-name {
        text-align: right;
    }

    .category-bar {
        background-color: red;
    }
}